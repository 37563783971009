import peopleListModule from '../peopleListModule';

function peopleInfoCardController(
  $scope,
  $log,
  resources,
  layoutService,
  chatDispatchService,
  $window,
  gamesService,
  eventStreamService,
  playGameService
) {
  'ngInject';

  $scope.sendEventStream = function(eventType, properties) {
    if (!properties) {
      properties = {};
    }
    properties.friendId = $scope.friend.id;
    properties.position = $scope.$index;
    eventStreamService.sendEventWithTarget(eventType.name, eventType.ctx, properties);
  };

  $scope.sendGamePlayEvent = function(rootPlaceId) {
    eventStreamService.sendGamePlayEvent(
      resources.eventStreamParams.gamePlayIntentInPeopleList.ctx,
      rootPlaceId
    );
  };

  $scope.clickBtn = function(where) {
    const { rootPlaceId } = $scope.friend.presence;
    const placeDetail = $scope.library.placesDict[rootPlaceId];
    const playButtonTypes = layoutService.playButtons;
    const propertiesForEvent = {
      rootPlaceId
    };
    const { eventStreamParams } = resources;
    switch (placeDetail.buttonLayout.type) {
      case playButtonTypes.join.type:
        var gameInstanceId = $scope.friend.presence.gameId;
        var playerId = $scope.friend.id;
        var playGameProperties = playGameService.buildPlayGameProperties(
          rootPlaceId,
          $scope.friend.presence.placeId,
          gameInstanceId,
          playerId
        );
        propertiesForEvent.gameInstanceId = gameInstanceId;
        propertiesForEvent.friendId = $scope.friend.id;
        propertiesForEvent.position = $scope.$index;
        var eventStreamProperties = {
          eventName: eventStreamParams.joinGameInPeopleList.name,
          ctx: eventStreamParams.joinGameInPeopleList.ctx,
          properties: propertiesForEvent,
          gamePlayIntentEventCtx: resources.eventStreamParams.gamePlayIntentInPeopleList.ctx
        };
        playGameService.launchGame(playGameProperties, eventStreamProperties);
        break;
      case playButtonTypes.details.type:
        propertiesForEvent.fromWhere = where;
        $scope.sendEventStream(eventStreamParams.goToGameDetailInPeopleList, propertiesForEvent);
        $window.location.href = $scope.friend.presence.placeUrl;
        break;
    }
  };

  $scope.goToGameDetails = function(where) {
    const { rootPlaceId } = $scope.friend.presence;
    const propertiesForEvent = {
      rootPlaceId,
      fromWhere: where
    };
    $scope.sendEventStream(
      resources.eventStreamParams.goToGameDetailInPeopleList,
      propertiesForEvent
    );
    $window.location.href = $scope.friend.presence.placeUrl;
  };

  $scope.goToChat = function() {
    const userId = $scope.friend.id;
    $scope.sendEventStream(resources.eventStreamParams.goToChatInPeopleList);
    const chatPermissionVerifier = chatDispatchService.buildPermissionVerifier({});
    chatDispatchService.startChat(userId, chatPermissionVerifier);
  };

  $scope.goToProfilePage = function() {
    $scope.sendEventStream(resources.eventStreamParams.goToGameDetailInPeopleList);
    $window.location.href = $scope.friend.profileUrl;
  };

  $scope.init = function() {
    $scope.sendEventStream(resources.eventStreamParams.openPeopleList);
  };

  $scope.init();
}

peopleListModule.controller('peopleInfoCardController', peopleInfoCardController);

export default peopleInfoCardController;
