import { Lang } from 'Roblox';
import angular from 'angular';

const peopleList = angular
  .module('peopleList', ['peopleListHtmlTemplateApp', 'robloxApp', 'ui.bootstrap', 'thumbnails'])
  .config([
    'languageResourceProvider',
    function(languageResourceProvider) {

      const resource = Lang.PeopleListResources;
      const translationConstants = {
        'Label.PresenceError': (resource && resource['Label.PresenceError']) || 'User status may be out of date',
        'Label.FriendsError' : (resource && resource['Label.FriendsError']) || 'Unable to load friends'
      }

      if (Lang.PeopleListResources) {
        languageResourceProvider.setLanguageKeysFromFile(Lang.PeopleListResources);
        languageResourceProvider.setLanguageKeysFromFile(translationConstants);
      }
    }
  ]);

export default peopleList;
